import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React,{ useContext } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    BooleanField,
    ReferenceInput,
    SelectInput,
    BooleanInput, SelectColumnsButton
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
import {
    Stack,
    Box
} from "@mui/material";
import { DefaultList } from "./defaults/DefaultList";
import { DefaultListRowEdit } from "./defaults/DefaultListRowEdit";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";

// ( (customer) => `${customer.first_name} ${customer.last_name}` )

const ListActions = () => {
    const { permissions } = usePermissions();
    const context = useContext(AdminUserContext);
    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        'customer.full_name': textFilter({ operators: [ 'eq' ] }),
        'customer.first_name': textFilter({ operators: [ 'eq' ] }),
        'customer.last_name': textFilter({ operators: [ 'eq' ] }),
        last_name: textFilter({ operators: [ 'eq' ] }),
        order_sent: booleanFilter({ operators: [ 'eq' ] }),
        state: choicesFilter({
            operators: [ 'eq' ],
            choices: context.orderStates
        }),
        status: choicesFilter({
            operators: [ 'eq' ],
            choices: context.orderStatuses
        }),
        subtotal: textFilter({ operators: [ 'eq' ] }),
        grand_total: textFilter({ operators: [ 'eq' ] }),
        increment_id: textFilter({ operators: [ 'eq' ] }),
    };

    return(
        <TopToolbar>
            {/*{ permissionManager.isAdmin(permissions) &&*/}
            {/*    <CreateButton />*/}
            {/*}*/}
            <SelectColumnsButton />
            <StackedFilters config={filterConfig} />
            <ExportButton />
        </TopToolbar>
    );
}
const SalesOrderListRowEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const context = useContext(AdminUserContext);
    return (
        <DefaultListRowEdit
            resource={ resource }
            recordId={ record.id }
            show={ false }
        >
            <Stack spacing={1}>
                <BooleanInput name="order_sent" source="order_sent" />
                <SelectInput name="state" source="state" choices={ context.orderStates } />
                <SelectInput name="status" source="status" choices={ context.orderStatuses } />
            </Stack>
            <Stack spacing={2} sx={ { marginLeft: '10px' } }>

            </Stack>
        </DefaultListRowEdit>
    );
}

export const SalesOrder = (prop) => {
    const context = useContext(AdminUserContext);
    console.log(process.env.REACT_APP_TEST_PARAM);
    return (
        <DefaultList
            actions={ <ListActions/> }
            resource="sales_orders"
            expand={ SalesOrderListRowEdit } expandSingle={ true }
            configurableList={true}
        >
            <TextField source="id" />

            <ReferenceField label="Customer" source="customerId" reference="customer_entities" sortable={false}>
                <TextField source="full_name" />
            </ReferenceField>
            <BooleanField source="order_sent" />
            <SelectField source="state" choices={ context.orderStates } />
            <SelectField source="status" choices={ context.orderStatuses } />
            <WithRecord label="Sub Total" render={
                record => (
                    <span>{ '£' + parseFloat(record.subtotal).toFixed(2) }</span>
                )
            } />
            <WithRecord label="Grand Total" render={
                record => (
                    <span>{ '£' + parseFloat(record.grand_total).toFixed(2) }</span>
                )
            } />
            <TextField source="increment_id" label="Sons ID"/>
            <TextField source="stripe_invoice_id" />
            <DateField source="updated_at" />
            <DateField source="created_at" />
        </DefaultList>
    );

};