import {
    Edit,
    SimpleForm,
} from 'react-admin';
import {Stack} from "@mui/material";
import React from "react";

export const DefaultListRowEdit = (props) => {
    return (
        <Edit
            resource={ props.resource }
            id={ props.recordId }
            /* disable the app title change when shown */
            title={ props.title ?? " " }
            show={ props.show }
        >
            <SimpleForm>
                <span style={ {
                    width: '100%',
                    marginBottom: '20px',
                    fontFamily: 'Aeroport-bold',
                    fontSize: '18px'
                } } >{ "Edit" }</span>
                <Stack
                    flexDirection="row"
                    mg={2}
                    sx={ { flexDirection: 'row', flexWrap: 'wrap', width: '100%' } }
                >
                    { props.children }
                </Stack>

            </SimpleForm>
        </Edit>
    );
};