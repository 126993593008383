
import React from "react";
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import { DefaultRowItem } from "./DefaultRowItem";

export const DefaultAddressFormRow = (props) => {

    return (
        <DefaultRowItem
            title={ "Default Address" }

            >
                <JsonSchemaForm
                    style={ { width: '600px', maxWidth: '90%' } }
                    schema={ {
                        type: 'object',
                        properties: {
                            note: {
                                title: '',
                                type: 'object',
                                properties: {
                                    default_address: {
                                        type: 'object',
                                        properties: {
                                            first_name: { type: 'string', title: 'First Name' },
                                            last_name: { type: 'string', title: 'Last Name' },
                                            name: { type: 'string' },
                                            phone: { type: 'string' },
                                            address1: { type: 'string', title: 'Address 1' },
                                            address2: { type: 'string', title: 'Address 2' },
                                            city: { type: 'string', title: 'City' },
                                            country_code: { type: 'string', title: 'Country Code' },
                                            country_name: { type: 'string', title: 'Country' },
                                            province: { type: 'string' },
                                            province_code: { type: 'string', title: 'Province Code' },
                                            zip: { type: 'string' },
                                            'default': { type: 'boolean' },
                                        }
                                    },
                                }
                            },
                        },
                    } }
                    uiSchema={ {
                        what_is_your_gender: {
                            'ui:widget': 'select',

                        },
                        average_order_amount: { 'ui:disabled': true },
                    } }
                />
        </DefaultRowItem>
    );
}