
import React from "react";
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import { DefaultRowItem } from "./DefaultRowItem";

export const GeneralRow = (props) => {

    return (
        <DefaultRowItem
            title={ "General" }
        >
            <JsonSchemaForm
                sx={ { width: '500px', maxWidth: '90%' } }
                schema={ {
                    type: 'object',
                    properties: {
                        note: {
                            title: '',
                            type: 'object',
                            properties: {
                                id: { type: 'string', title: 'ID' },
                                created_at: { type: 'string', title: 'Created At', format: 'date' },
                                first_name: { type: 'string', title: 'First Name' },
                                last_name: { type: 'string', title: 'Last Name' },
                                email: { type: 'string', title: 'Email Address' },
                                phone: { type: 'string', title: 'Phone Number' },
                                date_of_birth: { type: 'string', title: 'Date of Birth', format: 'date' },
                                what_is_your_gender: { type: 'string', title: 'Gender', enum: ['Male', 'Female'] },
                                average_order_amount: { type: 'string', title: 'Average Order Amount' },
                                consent_medical: { type: 'boolean', title: 'Consent Medical' },
                                accepts_marketing: { type: 'boolean' },
                                existing_sons_customer: { type: 'string', title: 'Existing Sons Customer', enum: ['Yes', 'No'] },
                                hair_loss_level: { type: 'string', title: 'Hair Loss Level' },
                                i_agree: { type: 'string', title: 'I agree' },
                                illness_caused_baldness: { type: 'boolean', title: 'Illness Caused Baldness' },
                                state: { type: 'string', enum: [ 'enabled', 'disabled'], },
                                outcome_date: { type: 'string', title: 'Outcome Date', format: 'date' },
                                statement_accepted: { type: 'boolean', title: 'Statement Accepted' },
                                survey_date: { type: 'string', title: 'Survey Date', format: 'date' },
                                survey_time: { type: 'string', title: 'Survey Time' },
                                service_statement: { type: 'string', title: 'Service Statement' },
                                tax_exempt: { type: 'boolean', title: 'Tax exempt' }
                            }
                        },
                    },
                } }
                uiSchema={ {
                    id: { 'ui:disabled': true },
                    created_at: { 'ui:disabled': true },
                } }
            />
        </DefaultRowItem>
    );
}