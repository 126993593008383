import { Admin, defaultTheme } from "react-admin";
export const SonsTheme = {
    ...defaultTheme,
    sidebar: {
        width: 80, // The default value is 240
        closedWidth: 35, // The default value is 55
    },
    typography: {
        fontFamily: ['Aeroport-light'].join(',')
    },
    palette: {
        // primary: {
        //     main: `#F0FAD7`, // has no effect on anything
        //     contrastText: `#F0FAD7`, // has no effect on anything
        // },
        secondary: {
            main: `#F0FAD7`, // has no effect on anything
            contrastText: `#F0FAD7`, // has no effect on anything
        },
        // text: {
        //     primary: '#263238',
        // },
    },
    components: {
        ...defaultTheme.components,
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: '#24272A',
                    fontFamily: 'Aeroport-bold'
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    "& .RaDatagrid-headerCell": {
                        fontFamily: "Aeroport-bold",
                    },
                }
            }


        }
    }
}