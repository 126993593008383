import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React,{ useContext } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    BooleanField,
    ReferenceInput,
    SelectInput,
    BooleanInput, SelectColumnsButton
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
import {
    Stack,
    Box
} from "@mui/material";
import { DefaultList } from "./defaults/DefaultList";
import { DefaultListRowEdit } from "./defaults/DefaultListRowEdit";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";

function fetchEfficacy() {
    let efficacy = [];
    for(let i = 0; i <= 100; i++) { //TODO - make this a setting
        efficacy.push({
            id: i.toString(),
            name: i.toString()
        });
    }
    return efficacy;
}


const ProductPlanListRowEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const context = useContext(AdminUserContext);
    return (
        <DefaultListRowEdit
            resource={ resource }
            recordId={ record.id }
            show={ false }
        >
            <Stack spacing={1}>
                <TextInput name="name" source="name" />
                <BooleanInput name="is_component" source="is_component" />
                <BooleanInput name="is_prescription_plan" source="is_prescription_plan" />
            </Stack>
            <Stack spacing={2} sx={ { marginLeft: '10px' } }>
                <SelectInput name="efficacy" source="efficacy" choices={ fetchEfficacy() } emptyValue="0" />
            </Stack>
        </DefaultListRowEdit>
    );
}

export const CatalogProductPlans = (prop) => {

    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        name: textFilter({ operators: [ 'eq' ] }),
        is_component: booleanFilter(),
        is_prescription_plan: booleanFilter(),
        parent_product_id: referenceFilter({
            operators: [ 'eq' ],
            reference: 'catalog_product_entities',
            optionText: 'name'
        }),
    };
    const ListActions = () => {
        const { permissions } = usePermissions();
        return(
            <TopToolbar>
                {/*{ permissionManager.isAdmin(permissions) &&*/}
                {/*    <CreateButton />*/}
                {/*}*/}
                <SelectColumnsButton />
                <StackedFilters config={ filterConfig } />
                <ExportButton />
            </TopToolbar>
        );
    }

    return (
        <DefaultList
            actions={ <ListActions/> }
            resource="catalog_product_plans"
            expand={ ProductPlanListRowEdit } expandSingle={ true }
            configurableList={true}
        >
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="base_type" />
            <TextField source="from_price" />
            <TextField source="efficacy" />
            <BooleanField source="is_component" />
            <BooleanField source="is_prescription_plan" />
            <ReferenceField source="parent_product_id" reference="catalog_product_entities" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
        </DefaultList>
    );

};