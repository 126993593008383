import React, { useContext, useState } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    BooleanField,
    Confirm
} from 'react-admin';
import {
    Button,
    Stack,
    Box,
    Typography,
    Divider
} from "@mui/material";
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { AdminUserContext } from "../../../contexts/AdminUserContextProvider";

import { AddressFormRow } from "./Rows/AddressFormRow";
import { DefaultAddressFormRow } from "./Rows/DefaultAddressFormRow";
import { GeneralRow } from './Rows/GeneralRow';
import { EmailMarketingFormRow } from "./Rows/EmailMarketingFormRow";
import { ExistingConditionsFormRow } from './Rows/ExistingConditionsFormRow';
import { TagsFormRow } from "./Rows/TagsFormRow";


export const JsonFormItemisedContainer = (props) => {

    let someArray = [
        'string',
        'number',
        'boolean'
    ];

    return (
        <Edit actions={ false } title=" ">
            <Box sx={ { display: 'flex', flexDirection: 'column', padding: '16px' } }>

                <Typography variant="h5" component="h5" sx={ { marginTop: '21px' } }>
                    <span>{ "Consultation Notes" }</span>
                </Typography>
                <Divider />
                <GeneralRow />
                <AddressFormRow />
                <DefaultAddressFormRow />
                <EmailMarketingFormRow />
                <ExistingConditionsFormRow />
                <TagsFormRow />
            </Box>

        </Edit>
    );
}