import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    BooleanField, SelectColumnsButton
} from 'react-admin';
import { DatagridAG } from "@react-admin/ra-datagrid-ag";
import { DefaultList } from "./defaults/DefaultList";
import { DefaultListRowEdit } from "./defaults/DefaultListRowEdit";
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
const ListActions = () => {
    const { permissions } = usePermissions();
    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        item_price: textFilter({ operators: [ 'eq' ] }),
        item_discount: textFilter({ operators: [ 'eq' ] }),
        item_name: textFilter({ operators: [ 'eq' ] }),grand_total: textFilter({ operators: [ 'eq' ] }),
        'product.name': textFilter({ operators: [ 'eq' ] }),
        'sales_order.increment_id': textFilter({ operators: [ 'eq' ] }),
    };
    return(
        <TopToolbar>
            {/*{ permissionManager.isAdmin(permissions) &&*/}
            {/*    <CreateButton />*/}
            {/*}*/}
            <SelectColumnsButton />
            <StackedFilters config={filterConfig} />
            <ExportButton />
        </TopToolbar>
    );
}
const SalesOrderItemListRowEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    return (
        <DefaultListRowEdit
            resource={ resource }
            recordId={ record.id }
            show={ false }
        >
            <TextInput name="product" source="product" />
            <TextInput name="component_sku" source="component_sku" />
            <TextInput name="shopify_product_id" source="shopify_product_id" />
        </DefaultListRowEdit>
    );
}


export const SalesOrderItems = (prop) => {
    const columnDefs = [
        {
            field: "id",
            editable: false,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            minWidth: 48,
            maxWidth: 48,
        },
        {
            field: "product_id",
            headerName: "Customiser Product ID",
        },
        {
            field: "item_name",
            headerName: "Product Name",
        },
        {
            field: "item_price",
            headerName: "Price Paid",
        },
        {
            field: "item_discount",
            headerName: "Discount on Line Item",
        },
    ];

    return (
        <DefaultList
            actions={ <ListActions /> }
            resource="sales_order_items"
            title="Sales Order Items"
            // expand={SalesOrderItemListRowEdit} expandSingle={ true }
            configurableList={true}
        >
            <TextField source="id" />
            <WithRecord label="Item Price" render={
                record => (
                    <span>{ '£' + parseFloat(record.item_price).toFixed(2) }</span>
                )
            } />
            <WithRecord label="Item Discount" render={
                record => (
                    <span>{ '£' + parseFloat(record.item_discount).toFixed(2) }</span>
                )
            } />
            <TextField source="item_name" />
            <ReferenceField source="sales_order" reference="sales_orders"  sortable={false}>
                <TextField source="increment_id" emptyText="Untitled" />
            </ReferenceField>
            <ReferenceField source="product" reference="catalog_product_entities" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="updated_at" />
            <DateField source="created_at" />
        </DefaultList>
    );
};