import {Confirm, useNotify, useUpdate} from "react-admin";
import React, {useContext, useState} from "react";
import {AdminUserContext} from "../../contexts/AdminUserContextProvider";
import {Box, Button, CircularProgress} from "@mui/material";
import {Link} from "react-router-dom";

export const PaymentLinkAction = (props) => {
    const notify = useNotify();
    const context = useContext(AdminUserContext);
    const record = props.record;
    const [paymentLink, setPaymentLink] = useState('Checking for link...');

    const getLink = async () => {
        const result = await context.fetchPaymentLink(record?.stripe_subscription_id, null);
        if (result) {
            setPaymentLink(result['url']);
        }
    }
    getLink();


    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={ { marginBottom: '30px' } }>
            <span>
                <span>{"Payment Link:"}</span>
                { !paymentLink &&
                    <span>{ "No link data availables" }</span>
                }
                { (paymentLink && paymentLink.includes('http')) &&
                    <Link to={ paymentLink } label={ paymentLink } />
                }
                { !paymentLink || !paymentLink.includes('http') &&
                    <span style={{cursor: 'pointer', marginLeft: '20px'}}>
                        {paymentLink}
                    </span>
                }

            </span>
            <span
                style={{fontSize: '12px'}}>{"Note: If payment has not been made on the most recent invoice, a link will be available" }</span>

        </Box>
    );
}
