import * as React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import Box from '@mui/material/Box';
const Logo = () => {
    return (
        <Box sx={ { paddingTop: '5px' } }>
            <Box
                sx={ { height: '35px' } }
                className="logo"
                component="img"
                src="/images/logo/logo.png"
            />
        </Box>
    );
}

export const CustomAppBar = () => (
    <AppBar
        sx={{
        background: '#24272A',
        '& .RaAppBar-toolbar': { padding: 0 },
    }}>

        <TitlePortal />
        <Logo />
        <Box flex="1" />

    </AppBar>
);