
import { Menu } from 'react-admin';
import {
        IconMenu,
        MenuItemList,
        MenuItemNode,
} from "@react-admin/ra-navigation";
import SettingsIcon from '@mui/icons-material/Settings';
import {
        Box,
        Stack
} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MedicationIcon from '@mui/icons-material/Medication';
import InventoryIcon from '@mui/icons-material/Inventory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ReportIcon from '@mui/icons-material/Report';
import { Search, SearchWithResult } from "@react-admin/ra-search";
const AdminMenu = () => {

    return (
        // <></>
        <IconMenu
            variant="categories"
            sx={ { textTransform: 'capitalize' } } >
                <IconMenu.Item
                    name="Dashbeoard"
                    to="/"
                    label="Dashboard"
                    icon={ <DashboardIcon /> } />
                <IconMenu.Item
                    name="Sales"
                    to=""
                    label="Sales"
                    icon={ <MonetizationOnIcon /> } >
                        <Stack
                            sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                            spacing={2}>
                                <Menu.ResourceItem name="sales_orders" />
                                <Menu.ResourceItem name="sales_order_items" />
                                <Menu.ResourceItem name="sales_subscriptions" />
                                <Menu.ResourceItem name="sales_coupon" />

                        </Stack>
                </IconMenu.Item>
                <IconMenu.Item
                    name="Customers"
                    to=""
                    label="Customers"
                    icon={ <PersonIcon /> } >
                        <Stack
                            sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                            spacing={2}>
                                <Menu.ResourceItem name="customer_entities" />
                                <Menu.ResourceItem name="sales_order_addresses" />
                        </Stack>
                </IconMenu.Item>
                <IconMenu.Item
                    name="Catalog"
                    to=""
                    label="Catalog"
                    icon={ <InventoryIcon /> } >
                        <Stack
                            sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                            spacing={2}>
                                <Menu.ResourceItem name="catalog_product_entities" />
                                <Menu.ResourceItem name="catalog_product_prices" />
                                <Menu.ResourceItem name="catalog_product_images" />
                                <Menu.ResourceItem name="catalog_product_plans" />
                                <Menu.ResourceItem name="catalog_pharmacy_lookup" />
                        </Stack>
                </IconMenu.Item>
            <IconMenu.Item
                name="Settings"
                to=""
                label="Settings"
                icon={ <SettingsIcon /> } >
                <Stack
                    sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                    spacing={2}>
                    <Menu.ResourceItem name="sales_shipping_methods" />
                    <Menu.Item to="/settings" primaryText="Settings" leftIcon={<SettingsIcon />} />
                    <Menu.ResourceItem name="admin_users" />

                </Stack>
            </IconMenu.Item>
            <IconMenu.Item
                name="Logs"
                to=""
                label="Logs"
                icon={ <ReportIcon /> }>
                <Stack
                    sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                    spacing={2}>
                    <Menu.ResourceItem name="message_messengers" label="Messenger Messages" />
                    <Menu.ResourceItem name="activity_monitors" label="Activity Monitor" />
                </Stack>
            </IconMenu.Item>
            <IconMenu.Item
                name="search"
                to=""
                label="Search"
                icon={ <SearchIcon /> }>
                <Stack
                    sx={ { padding: '20px', height: 'calc(100vh - 90px)' } }
                    spacing={2}>
                    <SearchWithResult />
                </Stack>

            </IconMenu.Item>
        </IconMenu>
    );
}
export default AdminMenu;