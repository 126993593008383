import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React,{ useContext } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    BooleanField,
    ReferenceInput,
    SelectInput,
    BooleanInput
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
import {
    Stack,
    Box
} from "@mui/material";
import { DefaultList } from "./defaults/DefaultList";
import { DefaultListRowEdit } from "./defaults/DefaultListRowEdit";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";

export const CatalogProductPrices = (prop) => {

    return (
       <DefaultList
           resource="catalog_product_prices"

       >
           <TextField source="id" />
           <TextField source="price" />
           <ReferenceField label="Product" source="catalogProductEntity" reference="catalog_product_entities" sortable={false}>
               <TextField source="name" />
           </ReferenceField>
           <TextField source="shopify_product_id" />
           <TextField source="stripe_id" />
           <DateField source="created_at" />
           <DateField source="updated_at" />
       </DefaultList>
    );

};