import React from "react";
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import { DefaultRowItem } from "./DefaultRowItem";

export const ExistingConditionsFormRow = (props) => {

    return (
        <DefaultRowItem
            title={ "Existing Conditions" }
        >
            <JsonSchemaForm
                sx={ { width: '500px', maxWidth: '90%' } }
                schema={ {
                    type: 'object',
                    properties: {
                        note: {
                            title: '',
                            type: 'object',
                            properties: {
                                existing_conditions: {
                                    type: 'array',
                                    items: {
                                        type: 'string',
                                        title: ''
                                    }
                                },
                            }
                        },
                    },
                } }
                uiSchema={ {
                    what_is_your_gender: {
                        'ui:widget': 'select',

                    },
                    average_order_amount: { 'ui:disabled': true },
                } }
            />
        </DefaultRowItem>
    );
}