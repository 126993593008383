import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    DatagridConfigurable
} from 'react-admin';
import {
    Box,
    Stack

} from "@mui/material";
import React from "react";

const customerFilters = [
    <SearchInput name="search_input" source="q" alwaysOn />,
];

export const DefaultList = (props) => {

    return (
        <List
            perPage={50}
            actions={ props.actions ?? null }
            filters={ customerFilters }
            resource={ props.resource }
            title={ props.title ?? '' }
            sort={ props.sort ?? undefined }
        >

            { props.configurableList &&
                <DatagridConfigurable
                    bulkActionButtons={false}
                    expand={ props.expand ?? null }
                    expandSingle={ props.expandSingle }
                    omit={ props.omit ?? null }
                >
                    { props.children }
                </DatagridConfigurable>

            }
            { !props.configurableList &&
                <Datagrid
                    bulkActionButtons={false}
                    expand={ props.expand ?? null }
                    expandSingle={ props.expandSingle }
                >
                    { props.children }
                </Datagrid>

            }



        </List>
    );
}