import React, { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import {
    Box,
    TextField,
    Button,
    ImageField,
    Stack
} from '@mui/material';
const Login = ({ theme }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = e => {
        e.preventDefault();
        if (errorMessage !== '') {
            setErrorMessage('');
        }
        if (username === '' || password === '') {
            setErrorMessage('Please enter a valid email and password');
        } else {
            login({ username, password })
                .catch(() =>
                    notify('Invalid email or password')
                );
        }
    }

    return (
        <Box className="page__login">
            <Box className="login__header">
                <Box
                    className="logo"
                    component="img"
                    src="/images/logo/logo.svg"
                />
            </Box>
            <Box className="login__body">
                <form
                    className="body__form"
                    onSubmit={handleSubmit}>
                    <Stack
                        justifyContent="space-between"
                        className="form__block">
                        <span className="block__title" >Welcome!</span>
                        <TextField
                            className="block__input"
                            type="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}/>
                        <TextField
                            className="block__input"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                        <button
                            className="button"
                                color="primary"
                            type="submit"
                        >
                            <span>{ "Login" }</span>
                        </button>
                        <Box className="messages">
                            { (errorMessage !== '') &&
                                <span>{ errorMessage }</span>
                            }
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Box>


    );

}

export default Login;