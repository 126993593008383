
import React from "react";
import {
    Button,
    Stack,
    Box,
    Typography,
    Divider
} from "@mui/material";
import { EditInDialogButton } from "@react-admin/ra-form-layout";

export const DefaultRowItem = (props) => {

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            sx={ { marginTop: '10px', paddingBottom: '5px', borderBottom: '1px solid #ccc' } }
        >
            <span style={ { fontSize: '18px', fontFamily: 'Aeroport-light' } } >{ props.title }</span>
            <EditInDialogButton title=" ">
                { props.children }
            </EditInDialogButton>
        </Box>
    );
}