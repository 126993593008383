import { combineDataProviders } from "react-admin";
import { addSearchMethod } from '@react-admin/ra-search';
import defaultDataProvider from './default/defaultDataProvider';
import subscriptionDataProvider from './default/subscriptionDataProvider';

export const dataProvider = combineDataProviders((resource) => {
    switch (resource) {
        // case 'sales_subscriptions':
        //     return subscriptionDataProvider;
        default:
            // return defaultDataProvider;
            return addSearchMethod(defaultDataProvider, {
                customer_entities: {
                    title: 'Customers',
                    label: 'full_name',
                    targets: [
                        'full_name'
                    ]
                    //description: record => `${record.full_name}`,
                },
                sales_order_addresses: {
                    label: 'postcode',
                    //description: record => `${record.street1}`,
                }
            },
                'show'
                );
    }
});