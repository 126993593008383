import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    SelectInput,
    ReferenceInput, SelectColumnsButton
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
} from '@react-admin/ra-form-layout';
import {
    Stack,
    Box
} from "@mui/material";
import { DefaultList } from "./defaults/DefaultList";
import { DefaultListRowEdit } from "./defaults/DefaultListRowEdit";

const filterConfig = {
    id: numberFilter({ operators: [ 'eq' ] }),
    product: referenceFilter({
        operators: [ 'eq' ],
        reference: 'catalog_product_entities',
        optionText: 'name'
    }),
    component_sku: textFilter({ operators: [ 'eq' ] }),
    shopify_product_id: textFilter({ operators: [ 'eq' ] })
};

const ListActions = () => {
    const { permissions } = usePermissions();
    return(
        <TopToolbar>
            {/*{ permissionManager.isAdmin(permissions) &&*/}
            {/*    <CreateButton />*/}
            {/*}*/}
            <SelectColumnsButton />
            <StackedFilters config={filterConfig} />
            <ExportButton />
        </TopToolbar>
    );
}
const CatalogPharmacyListRowEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const optionRendered = choice => `${choice.name}`;
    return (
        <DefaultListRowEdit
            resource={ resource }
            recordId={ record.id }
            show={ false }
        >
            <Stack spacing={1}>
                <ReferenceInput name="product" source="product" reference="catalog_product_entities">
                    <SelectInput name="title" source="title" optionText={ optionRendered } />
                </ReferenceInput>
                <TextInput name="component_sku" source="component_sku" />
                <TextInput name="shopify_product_id" source="shopify_product_id" />
            </Stack>
        </DefaultListRowEdit>
    );
}



export const CatalogPharmacyList = (props) => {

    return (
        <DefaultList
            actions={ <ListActions/> }
            resource="catalog_pharmacy_lookups"
            expand={CatalogPharmacyListRowEdit} expandSingle={ true }
            configurableList={true}
        >
            <TextField source="id" />
            <ReferenceField source="product" reference="catalog_product_entities" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="component_sku" />
            <TextField source="shopify_product_id" />
        </DefaultList>
    );
};