import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    SelectInput, SelectColumnsButton
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
import { DatagridAG } from "@react-admin/ra-datagrid-ag";
import { DefaultList } from "./defaults/DefaultList";
import { DefaultListRowEdit } from "./defaults/DefaultListRowEdit";
import React, { useContext } from "react";
import {
    Stack,
    Box
} from "@mui/material";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";


const SalesOrderAddressListRowEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const context = useContext(AdminUserContext);

    return (
        <DefaultListRowEdit
            resource={ resource }
            recordId={ record.id }
            show={ false }
        >
            <Stack spacing={2}>
                <SelectInput name="address_type" source="address_type" choices={ context.addressTypes } />
                <TextInput name="first_name" source="first_name" />
                <TextInput name="surname" source="surname" />
            </Stack>
            <Stack spacing={2} sx={ { marginLeft: '10px' } }>
                <TextInput label="Street One" name="street1" source="street1" />
                <TextInput label="Street Two" name="street2" source="street2" />
                <TextInput name="phone" source="phone" />

            </Stack>
            <Stack spacing={2} sx={ { marginLeft: '10px' } }>
                <TextInput name="city" source="city" />
                <TextInput name="postcode" source="postcode" />
                <TextInput name="county" source="county" />

            </Stack>

            <Stack spacing={2} sx={ { marginLeft: '10px' } }>
                <SelectInput name="country" source="country" choices={ context.countryList } />
            </Stack>



        </DefaultListRowEdit>
    );
}

export const SalesOrderAddress = (prop) => {
    const context = useContext(AdminUserContext);

    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        address_type: choicesFilter({
            operators: [ 'eq' ],
            choices: context.addressTypes
        }),
        first_name: textFilter({ operators: [ 'eq' ] }),
        last_name: textFilter({ operators: [ 'eq' ] }),
        street1: textFilter({ operators: [ 'eq']  }),
        street2: textFilter({ operators: [ 'eq' ] }),
        phone: textFilter({ operators: [ 'eq' ] }),
        city: textFilter({ operators: [ 'eq' ] }),
        postcode: textFilter({ operators: [ 'eq' ] }),
        county: textFilter({ operators: [ 'eq' ] }),
        country: choicesFilter({
            operators: [ 'eq' ],
            choices: context.countryList
        }),
    };

    const ListActions = () => {
        const { permissions } = usePermissions();
        return(
            <TopToolbar>
                {/*{ permissionManager.isAdmin(permissions) &&*/}
                {/*    <CreateButton />*/}
                {/*}*/}
                <SelectColumnsButton />
                <StackedFilters config={ filterConfig } />
                <ExportButton />
            </TopToolbar>
        );
    }

    const customerFilters = [
        <SearchInput name="search_input" source="q" alwaysOn />,
    ];

    return (
        <DefaultList
            actions={ <ListActions/> }
            filters={ customerFilters }
            resource="sales_order_addresses"
            expand={ SalesOrderAddressListRowEdit } expandSingle={ true }
            configurableList={true}
        >
            <TextField source="id" />
            <SelectField label="Type" source="address_type"  choices={ context.addressTypes } />
            <TextField source="first_name" />
            <TextField source="surname" />
            <TextField label="Street One" source="street1" />
            <TextField label="Street Two" source="street2" />
            <TextField source="phone" />
            <TextField source="city" />
            <TextField source="postcode" />
            <TextField source="county" />
            <SelectField source="country"  choices={ context.countryList } />
        </DefaultList>
    );

};