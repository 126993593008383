import React, { useContext, useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    useRecordContext,
    SelectInput,
    ReferenceInput,
    WithRecord,
    ReferenceManyField,
    Datagrid,
    EditButton,
    TextField,
    ReferenceField,
    ReferenceArrayInput, DateInput
} from 'react-admin';
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import {
    Box,
    Grid
} from '@mui/material';
import { JsonFormItemisedContainer } from "./Form/Notes/JsonFormItemisedContainer";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";

const EditAddressTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{ "Customer - " + record.full_name }</span>
}

export const CustomerEdit = () => {
    return (
        <>
            <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sx={ { display: 'flex', flexDirection: 'column' } }>

                    <Edit title={ <EditAddressTitle /> } actions={ false }>
                        <Grid container spacing={ 2 }>
                            <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                <SimpleForm>
                                    <Grid container spacing={ 2 }>
                                        <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                            <TextInput name="first_name" source="first_name" />
                                            <TextInput name="last_name" source="last_name" />
                                            <TextInput name="email_address" source="email_address" />
                                            <TextInput name="phone_number" source="phone_number" />
                                        </Grid>
                                        <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                            <TextInput name="shopify_customer_id" source="shopify_customer_id"/>
                                            <TextInput name="stripe_customer_id" source="stripe_customer_id"/>
                                            <DateInput source="dateOfBirth" name="dateOfBirth" />
                                            <span>{"Subscriptions"}</span>
                                            <ReferenceField
                                                label="Subscription"
                                                source="subscription"
                                                reference="sales_subscriptions">
                                                <TextField source="stripe_subscription_id"/>
                                            </ReferenceField>
                                        </Grid>
                                    </Grid>
                                </SimpleForm>
                            </Grid>
                            <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                                <Box sx={ { display: 'flex', flexDirection: 'column' } }>
                                    <span>{ "Orders" }</span>
                                    <ReferenceManyField
                                        perPage={10}
                                        source="sales_order"
                                        target="id"
                                        reference="sales_orders">
                                        <Datagrid>
                                            <TextField source="id"/>
                                            <TextField source="increment_id"/>
                                            <EditButton />
                                        </Datagrid>
                                    </ReferenceManyField>
                                </Box>
                                <Box sx={ { display: 'flex', flexDirection: 'column' } }>


                                </Box>
                            </Grid>
                        </Grid>
                    </Edit>
                </Grid>
            </Grid>
            <Grid container spacing={ 2 }>
                    <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                        <Edit title=" " actions={ false }>
                            <JsonSchemaForm
                                schema={ {
                                    type: 'object',
                                    properties: {
                                        note: {
                                            title: 'Consultation Notes - quick view',
                                            type: 'object',
                                            properties: {
                                                first_name: { type: 'string', title: 'First Name' },
                                                last_name: { type: 'string', title: 'Last Name' },
                                                email: { type: 'string', title: 'Email Address' },
                                                phone: { type: 'string', title: 'Phone Number' },
                                                date_of_birth: { type: 'string', title: 'Date of Birth', format: 'date' },
                                                what_is_your_gender: { type: 'string', title: 'Gender', enum: ['Male', 'Female'] },
                                                average_order_amount: { type: 'string', title: 'Average Order Amount' },
                                               consent_medical: { type: 'boolean', title: 'Consent Medical' },
                                               accepts_marketing: { type: 'boolean' },
                                            }
                                        },
                                    },
                                } }
                                uiSchema={ {
                                    average_order_amount: { 'ui:disabled': true },
                                } }
                            />
                        </Edit>
                    </Grid>
                    <Grid item xs={ 6 } sx={ { display: 'flex', flexDirection: 'column' } }>
                        <JsonFormItemisedContainer />
                    </Grid>

            </Grid>

        </>

    );



}