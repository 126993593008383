import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React,{ useContext } from "react";
import {
    TextField,
    usePermissions,
    TextInput,
    TopToolbar,
    ExportButton,
    useRecordContext,
    useResourceContext, SelectColumnsButton,
} from 'react-admin';
import {
    textFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
import {
    Stack,
    Box
} from "@mui/material";
import { DefaultList } from "./defaults/DefaultList";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import {DefaultListRowEdit} from "./defaults/DefaultListRowEdit";

const ListActions = () => {
    const { permissions } = usePermissions();
    const context = useContext(AdminUserContext);
    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        shipping_method_name: textFilter({ operators: [ 'eq' ] }),
        shipping_price: textFilter({ operators: [ 'eq' ] }),
        stripe_product_id: textFilter({ operators: [ 'eq' ] }),
    };

    return(
        <TopToolbar>
            {/*{ permissionManager.isAdmin(permissions) &&*/}
            {/*    <CreateButton />*/}
            {/*}*/}
            <SelectColumnsButton />
            <StackedFilters config={ filterConfig } />
            <ExportButton />
        </TopToolbar>
    );
}

const MethodEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    return (
        <DefaultListRowEdit
            resource={ resource }
            recordId={ record.id }
            show={ false }
        >
            <Stack spacing={2}>
                <TextInput name="shipping_method_name" source="shipping_method_name" />
                <TextInput name="shipping_price" source="shipping_price" />
                <TextInput name="stripe_product_id" source="stripe_product_id" />
            </Stack>
            <Stack spacing={2} sx={ { marginLeft: '10px' } }>
                <TextInput name="shipping_method" source="shipping_method" />
            </Stack>
        </DefaultListRowEdit>
    );
};


export const ShippingMethods = (prop) => {

    return (
        <DefaultList
            actions={ <ListActions/> }
            resource="sales_shipping_methods"
            expand={MethodEdit} expandSingle={ true }
            configurableList={true}
        >
            <TextField source="id" />
            <TextField source="shipping_method_name" />
            <TextField source="shipping_price" />
            <TextField source="stripe_product_id" />
        </DefaultList>
    );

};