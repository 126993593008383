
import React from "react";
import { JsonSchemaForm } from '@react-admin/ra-json-schema-form';
import { DefaultRowItem } from "./DefaultRowItem";
import {
    useRecordContext,
} from 'react-admin';

export const EmailMarketingFormRow = (props) => {
    return (
        <DefaultRowItem
            title={ "Email Marketing" }
            >
                <JsonSchemaForm
                    sx={ { width: '500px', maxWidth: '90%' } }
                    schema={ {
                        type: 'object',
                        properties: {
                            note: {
                                title: '',
                                type: 'object',
                                properties: {
                                    email_marketing_consent: {
                                        type: 'object',
                                        properties: {
                                            consent_updated_at: { type: 'string', format: 'date' },
                                            marketing_opt_in_level: { type: 'string', title: 'Marketing Opt In Level', enum: [ "SINGLE_OPT_IN" ] },
                                            marketing_state: { type: 'string', title: 'Marketing Status', enum: [ 'UNSUBSCRIBED', 'SUBSCRIBED' ] }
                                        }
                                    },
                                }
                            },
                        },
                    } }
                    uiSchema={ {
                        what_is_your_gender: {
                            'ui:widget': 'select',

                        },
                        average_order_amount: { 'ui:disabled': true },
                    } }
                />
        </DefaultRowItem>
    );
}