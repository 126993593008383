import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React,{ useContext } from "react";
import {
    TextField,
    ReferenceField,
    DateField,
    WithRecord,
} from 'react-admin';
import { DefaultList } from "./defaults/DefaultList";

export const CatalogProductImages = (prop) => {

    return (
        <DefaultList
            resource="catalog_product_images"
        >
            <TextField source="id" />
            <WithRecord label="Image Preview" render={ (record) => {
                // console.log(record);
                const imageUrl = 'https://shop.sons.co.uk/_next/image?url=' + record.file_path + '&w=768&q=90';
                return (
                    <img src={imageUrl} name={ record.id} style={{maxWidth: '200px'}}/>
                )
            }}/>
            <TextField source="width" />
            <TextField source="height" />
            <ReferenceField label="Product" source="product" reference="catalog_product_entities" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="updated_at" />
            <DateField source="created_at" />
        </DefaultList>
    );

};