import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React,{ useContext } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    BooleanField,
    ReferenceInput,
    SelectInput,
    BooleanInput, SelectColumnsButton
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
import {
    Stack,
    Box
} from "@mui/material";
import { DefaultList } from "./defaults/DefaultList";
import { DefaultListRowEdit } from "./defaults/DefaultListRowEdit";
import { AdminUserContext } from "../contexts/AdminUserContextProvider";

const ListActions = () => {
    const { permissions } = usePermissions();
    const context = useContext(AdminUserContext);
    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        name: textFilter({ operators: [ 'eq' ] }),
        sku: textFilter({ operators: [ 'eq' ] }),
        product_type: choicesFilter({
            operators: [ 'eq' ],
            choices: context.productTypes
        }),
        status: booleanFilter(),
        system_defined: booleanFilter()
    };

    return(
        <TopToolbar>
            {/*{ permissionManager.isAdmin(permissions) &&*/}
            {/*    <CreateButton />*/}
            {/*}*/}
            <SelectColumnsButton />
            <StackedFilters config={filterConfig} />
            <ExportButton />
        </TopToolbar>
    );
}
const CatalogProductListRowEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const context = useContext(AdminUserContext);
    return (
        <DefaultListRowEdit
            resource={ resource }
            recordId={ record.id }
            show={ false }
        >
            <Stack spacing={1}>
                <BooleanInput name="status" source="status" />
                <TextInput name="sku" source="sku" />
                <TextInput name="name" source="name" />
            </Stack>
            <Stack spacing={2} sx={ { marginLeft: '10px' } }>
                <SelectInput name="product_types" source="product_type" choices={ context.productTypes } />
                <TextInput name="shopify_product_id" source="shopify_product_id" />
                <BooleanInput name="system_defined" source="system_defined" />
            </Stack>
            <Stack spacing={2} sx={ { marginLeft: '10px' } }>
                <BooleanInput name="enabled" source="enabled" />
            </Stack>
        </DefaultListRowEdit>
    );
}

export const CatalogProducts = (prop) => {
    const context = useContext(AdminUserContext);


    return (
        <DefaultList
            resource="catalog_product_entities"
            actions={ <ListActions/> }
            expand={ CatalogProductListRowEdit } expandSingle={ true }
            configurableList={true}
        >
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="sku" />
            <SelectField source="product_type" choices={ context.productTypes } />
            <BooleanField source="status" />
            <BooleanField source="system_defined" />
            <DateField source="stripe_last_updated" />
            <TextField source="shopify_product_id" />
            <TextField label="Stripe Interval" source="stripeInterval" />
            <TextField name="Stripe Interval Count" source="stripeIntervalCount" />
            <DateField source="updated_at" />
            <DateField source="created_at" />
        </DefaultList>
    );

};