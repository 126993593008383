import React, { useMemo } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    Show,
    WrapperField
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
} from '@react-admin/ra-form-layout';
import {
    Box,
    Stack
} from "@mui/material";


const MessengerMessagesExpand = (props) => {
    const record = useRecordContext();
    const resource = useResourceContext();
    return (
        <Edit
            resource={ resource }
            id={ record.id }
            actions={ false }
            title=" "
        >
            <TextField source="id" />
        </Edit>
    );
}

export const MessengerMessages = (props) => {


    return (
        <List
            resource="message_messengers"
            title="Messenger Messages"
        >
            <Datagrid
                bulkActionButtons={false}
            >
                <TextField source="id" />
                <TextField
                    sx={ { maxWidth: '800px', height: '500px', margin: '10px', overflowY: 'auto' } }
                    source="body.content" component="pre" />
                <WithRecord label="Queue Name" render={ (record) => {
                    switch (record.queue_name) {
                        case 'failed':
                            return (
                                <span style={ { color: "red", textTransform: 'capitalize' } }>{ record.queue_name }</span>
                            )
                    }
                }} />
                <TextField
                    sx={ { maxWidth: '800px', height: '500px', margin: '10px', overflowY: 'auto' } }
                    source="headers.type" component="pre" />
                <DateField source="created_at" />
            </Datagrid>
        </List>
    );
}