import { jwtDecode } from "jwt-decode";
import { hostUrl } from "../config";

export const storeToken = (token) => {
    const decodedToken = jwtDecode(token);
    localStorage.setItem("token", token);
    localStorage.setItem("groups", JSON.stringify(decodedToken.roles));
    return decodedToken.id;
};

export const getUser = async (id) => {
    return dataProvider.getOne("users", { id: `/api/users/${id}` });
};

const isAuthenticated = () => {
    const token = localStorage.getItem("token");

    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp && typeof decodedToken.exp === "number") {
            if (Date.now() < decodedToken.exp * 1000) {
                return decodedToken;
            }
        }
    }

    return false;
};

const removeStoredData = () => {
    if (!localStorage) {
        return;
    }
    localStorage.removeItem("auth");
    localStorage.removeItem("profile");
    localStorage.removeItem('stored_k');
    localStorage.removeItem('stored_s');
    localStorage.removeItem('stored_data');
};

export const authProvider = {


    login: async ({ username, password }) => {
        const request = new Request(hostUrl + "admin/user/login?XDEBUG_SESSION_START=PHPSTORM", {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: new Headers({
                "Content-Type": "application/json"
            })
        });

        try {
            const response = await fetch(request);
            // debugger;
            if (response.status < 200 || response.status >= 300) {
                //TODO - do something
                return Promise.reject(response);
            }
            const data = await response.json();
            const token = data.token;
            if (token) {
                localStorage.setItem("auth", token);
                localStorage.setItem('profile', JSON.stringify({
                    id: data.id,
                    fullName: data.name,
                    // avatar: data.avatar,
                    roles: data.permissions
                }));
            }
        } catch (error) {
            console.error(error);
        }
        return Promise.resolve();

    },
    getIdentity: async () => {
        try {
            const profile = localStorage.getItem("profile");
            if (profile) {
                // const ava
                const { id, fullName } = profile;
                return { id, fullName  }
            } else {
                return Promise.reject('login' );
            }
        } catch (error) {
            return Promise.reject('login' );
        }
    },
    logout:  async () => {
        removeStoredData();
        return Promise.resolve();
    },
    checkAuth: async () => {
        let auth = localStorage.getItem("auth") ?? null;
        if (auth == null) {
            return Promise.reject('login' );
        }
        return Promise.resolve();

    },
    checkError: async (error) => {
        // debugger;
        // const status = error?.status || error?.response?.status;
        // if (status === 401 || status === 403) {
        //     removeStoredData();
        // }
        return Promise.resolve();
    },
    getPermissions: async () => {
        // debugger;
        const profile = JSON.parse(localStorage.getItem('profile'));
        return profile.roles.split(',');
    }
};
