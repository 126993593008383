import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useMemo } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput, SelectColumnsButton,
    WrapperField
} from 'react-admin';
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
} from '@react-admin/ra-form-layout';
import {
    Box,
    Stack
} from "@mui/material";
import { DefaultList } from "./defaults/DefaultList";
import { DefaultListRowEdit } from "./defaults/DefaultListRowEdit";


const filterConfig = {
    id: numberFilter({ operators: [ 'eq' ] }),
    full_name: textFilter({ operators: [ 'eq' ] }),
    first_name: textFilter({ operators: [ 'eq' ] }),
    last_name: textFilter({ operators: [ 'eq' ] }),
    email: textFilter({ operators: [ 'eq' ] }),
    phone_number: textFilter({ operators: [ 'eq' ] }),
    shopify_customer_id: textFilter({ operators: [ 'eq' ] }),
};

const ListActions = () => {
    const { permissions } = usePermissions();
    return(
        <TopToolbar>
            {/*{ permissionManager.isAdmin(permissions) &&*/}
            {/*    <CreateButton />*/}
            {/*}*/}
            <SelectColumnsButton />
            <StackedFilters config={ filterConfig } />
            <ExportButton />
        </TopToolbar>
    );
}
const CustomerEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    return (
        <DefaultListRowEdit
            resource={ resource }
            recordId={ record.id }
            show={ false }
        >
            <Stack spacing={2}>
                <TextInput name="first_name" source="first_name" />
                <TextInput name="last_name" source="last_name" />
                <TextInput name="email_address" source="email_address" />
            </Stack>
            <Stack spacing={2} sx={ { marginLeft: '10px' } }>
                <TextInput name="phone_number" source="phone_number" />
                <TextInput name="shopify_customer_id" source="shopify_customer_id" />
                <TextInput name="stripe_customer_id" source="stripe_customer_id" />
            </Stack>
        </DefaultListRowEdit>
    );
};

export const Customers = (prop) => {


    return (
        <DefaultList
            actions={ <ListActions/> }
            resource="customer_entities"
            expand={CustomerEdit} expandSingle={ true }
            configurableList={true}
        >
            <TextField source="id" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="email_address" />
            <TextField source="phone_number" />
            <TextField source="shopify_customer_id" />
            <TextField source="stripe_customer_id" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <WrapperField label="Actions">
                <EditButton />
            </WrapperField>
        </DefaultList>
    );
};