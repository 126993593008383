import {Confirm, useNotify, useUpdate} from "react-admin";
import React, {useContext, useState} from "react";
import {AdminUserContext} from "../../contexts/AdminUserContextProvider";
import {Box, Button, CircularProgress} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CancelIcon from "@mui/icons-material/Cancel";


export const ResumeAction = (props) => {
    const notify = useNotify();
    const context = useContext(AdminUserContext);
    const [openResume, setOpenResume] = useState(false);
    const record = props.record;
    const newRecord = structuredClone(record);
    newRecord.state = 'active';
    const [update] = useUpdate(
        'sales_subscriptions',
        { id: record.id, data: newRecord, previousData: record }
    );
    const handleResumeClick = () => setOpenResume(true);
    const handleResumeDialogClose = () => setOpenResume(false);
    const handleResumeConfirm = async () => {
        let updated = await context.resumeSubscription(record.stripe_subscription_id);
        if (updated) {
            await update();
            notify("Subscription Resumed")
        }
        if (Array.isArray(updated)) {
            notify(updated.error_message);
        }
        setOpenResume(false);

    };
    return (
        <Box>
            <Button title="Resume Subscription" onClick={ handleResumeClick } >
                { !openResume &&
                    <>
                        { props.fullButton &&
                            <span>Resume</span>
                        }
                        { !props.fullButton &&
                            <RestartAltIcon />
                        }
                    </>

                }
                { openResume &&
                    <CircularProgress size={20} color="secondary" />
                }
            </Button>
            <Confirm
                isOpen={ openResume }
                title="Resume Subscroption"
                content="Are you sure you want to resume this subscription?"
                onConfirm={ handleResumeConfirm }
                onClose={ handleResumeDialogClose }
            />
        </Box>
    );
}