import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useContext, useState } from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    usePermissions,
    EditButton,
    TextInput,
    ReferenceField,
    TopToolbar,
    CreateButton,
    FilterButton,
    ExportButton, SelectField,
    useRecordContext,
    useResourceContext,
    Edit,
    SimpleForm,
    DateField,
    DateInput,
    SearchInput,
    WithRecord,
    BooleanField,
    Confirm,
    SelectColumnsButton
} from 'react-admin';
import { DefaultList } from "./defaults/DefaultList";
import {
    textFilter,
    dateFilter,
    booleanFilter,
    referenceFilter,
    numberFilter,
    StackedFilters,
    choicesFilter
} from '@react-admin/ra-form-layout';
import {
    Button,
    Stack,
    Box,
    CircularProgress
} from "@mui/material";
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { AdminUserContext } from "../contexts/AdminUserContextProvider";
import { CancelAction } from "../Defaults/Subscriptions/CancelAction";
import { PauseAction } from "../Defaults/Subscriptions/PauseAction";
import {ResumeAction} from "../Defaults/Subscriptions/ResumeAction";


const ActionRowItem = (props) => {
    const record = props.record;
    return (
        <Box
            display="flex"
            sx={ {   } }
        >
            { record.state === 'active' &&
                <>
                    <PauseAction record={ record } />
                    <CancelAction record={ record } />
                </>
            }
            { record.state === 'paused' &&
                <ResumeAction record={ record } />
            }
            <Box>
                <EditButton />
            </Box>


        </Box>
    );
}

export const SalesSubscription = (prop) => {
    const context = useContext(AdminUserContext);

    const filterConfig = {
        id: numberFilter({ operators: [ 'eq' ] }),
        stripe_subscription_id: textFilter({ operators: [ 'eq' ] }),
        is_trial: booleanFilter(),
        'customer.full_name': textFilter({ operators: [ 'eq' ] }),
        'customer.first_name': textFilter({ operators: [ 'eq' ] }),
        'customer.last_name': textFilter({ operators: [ 'eq' ] }),
        'customer.email_address': textFilter({ operators: [ 'eq' ] }),
        currency: choicesFilter({
            operators: [ 'eq' ],
            choices: context.currencies
        }),
        'shipping_method.name': textFilter({ operators: [ 'eq' ] }),
        phone_number: textFilter({ operators: [ 'eq' ] }),
        shopify_customer_id: textFilter({ operators: [ 'eq' ] }),
    };
    const ListActions = () => {
        const { permissions } = usePermissions();
        return(
            <TopToolbar>
                {/*{ permissionManager.isAdmin(permissions) &&*/}
                {/*    <CreateButton />*/}
                {/*}*/}
                <SelectColumnsButton />
                <StackedFilters config={ filterConfig } />
                <ExportButton />
            </TopToolbar>
        );
    }
    function getCurrencySymbol(id) {
        let symbol = '£';
        context.currencies.forEach((item) => {
            if (item.id == id) {
                symbol = item.symbol;
            }
        })
        return symbol;
    }

    const omitColumns = [
        "stripe_subscription_id",
        "currency",
        "one_off_total",
        'is_trial',
        'shipping_method',
        'created_at',
        'oneOffTotal'
    ];

    return (
        <DefaultList
            actions={ <ListActions/> }
            resource="sales_subscriptions"
            configurableList={true}
            omit={ omitColumns }
            sort={{ field: 'id', order: 'DESC' }}
        >
            <TextField source="id" />
            <ReferenceField label="Customer" source="customer" reference="customer_entities" sortable={false}>
                <TextField source="full_name" />
            </ReferenceField>
            <ReferenceField
                label="Customer Email"
                source="customer"
                reference="customer_entities"
                link={ false }
                sortable={false}>
                <TextField source="email_address" />
            </ReferenceField>
            <TextField source="stripe_subscription_id" />
            <SelectField source="currency" choices={ context.currencies } />
            <WithRecord label="One Off Total" source="one_off_total" render={
                record => (
                    <span>{ getCurrencySymbol(record.currency) + parseFloat(record.one_off_total).toFixed(2) }</span>
                )
            } />
            <BooleanField source="is_trial" />
            <DateField source="next_cycle_date" />

            <ReferenceField label="Shipping Method" source="shipping_method" reference="sales_shipping_methods"  sortable={false}>
                <TextField source="shipping_method_name" />
            </ReferenceField>
            <TextField label="Subscription Status" source="state" sx={ { textTransform: 'capitalize' } } />
            <WithRecord label="Total Order Value" render={
                record => (
                    <span>{ getCurrencySymbol(record.currency) + parseFloat(record.total_order_value).toFixed(2) }</span>
                )
            } />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <WithRecord label="Actions" render={
                record => <ActionRowItem record={record} />
            } />

        </DefaultList>
    );

};